export const page = {
  data() {
    return { content: "" };
  },
  mounted() {
    const pageName = this.$options.name.toLowerCase();
    this.getContent(pageName).then((res) => {
      if (res) {
        this.content = JSON.parse(res.data[0].content);
      }
    });
  },
};
